import { Fragment, useState } from "react";
import "./Wellcome.scss";
import SuccessModal from "../../components/success-modal/SuccessModal";
import EmailForm from "../../components/email/Email";
import { subscribe } from "../../constants";
import IconTwitter from "../../assets/images/ic-twitter.svg";
import IconInstagram from "../../assets/images/ic-instagram.svg";
import IconLinkedin from "../../assets/images/ic-linkedin.svg";
// import IconDiscrod from "../../assets/images/ic-discord.svg";
import IconTelegram from "../../assets/images/ic-telegram.svg";
import IconMedium from "../../assets/images/ic-medium.svg";
import SocialAccount from "../../components/social-account";

const Wellcome = () => {
  const [isOpen, setIsOpen] = useState(false);
  const modalHandle = () => setIsOpen(true);
  const closeModalState = () => {
    setTimeout(() => {
      setIsOpen(false);
    }, 800);
  };

  return (
    <>
      <div className="wellcome wellcome-bg">
        <div className="flex-1 flex justify-center container">
          <div className="wellcome__flexcenter relative z-10">
            <h1>
              <img
                className="md:-mt-12 -mt-5 md:-mb-40 -mb-20 md:w-auto w-96 -translate-y-3 animated logo relative -z-10"
                src={require(`../../assets/images/logo.png`)}
                alt="Benofity"
                srcSet={`${require(`../../assets/images/logo.png`)} 1x, ${require(`../../assets/images/logo@2x.png`)} 2x`}
              />
            </h1>
            <h3>Our website is under construction</h3>
            <p className="text-silver-sand">
              To learn more about CovenLabs projects and get project launch
              notifications, <br className="lg:block hidden" />
              please subscribe to our newsletter.
            </p>
            <EmailForm
              config={subscribe}
              btnText="Subscribe"
              modalHandle={modalHandle}
            />
          </div>
        </div>
        <div className="wellcome__social">
          <SocialAccount
            icon={IconTwitter}
            title={"Twitter"}
            link={"https://twitter.com/covenlabsxyz"}
          />
          <SocialAccount
            icon={IconInstagram}
            title={"Instagram"}
            link={"https://www.instagram.com/covenlabs.xyz"}
          />
          <SocialAccount
            icon={IconLinkedin}
            title={"Linkedin"}
            link={"https://www.linkedin.com/company/covenlabs"}
          />
          {/* <SocialAccount
                icon={IconDiscrod}
                title={"Discord"}
                link={"/DC"}
              /> */}
          <SocialAccount
            icon={IconTelegram}
            title={"Telegram"}
            link={"https://t.me/+GO0C0gSsZsRjM2Vk"}
          />
          <SocialAccount
            icon={IconMedium}
            title={"Medium"}
            link={"https://medium.com/@covenlabs"}
          />
          <span className="w-full text-center text-white mt-5">
            © 2022 CovenLabs
          </span>
        </div>
      </div>

      {isOpen ? (
        <SuccessModal
          closeModalState={closeModalState}
          title="Subscribed!"
          description={
            <Fragment>
              You will receive the latest updates and we can't{" "}
              <br className="md:block hidden" />
              wait to share our projects with you.
            </Fragment>
          }
        />
      ) : null}
    </>
  );
};

export default Wellcome;
